<template>
  <div class="view pa24">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="活动类型" prop="killType">
        <el-select
          v-model="ruleForm.killType"
          :disabled="$route.query.id ? true : false"
        >
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item.label"
            :value="item.id"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动时长" prop="pickerValue">
        <el-date-picker
          v-model="ruleForm.pickerValue"
          value-format="yyyy-MM-dd"
          align="right"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="单账号最多可购买数">
        <el-input-number
          :disabled="$route.query.id ? true : false"
          v-model="ruleForm.maxPurchase"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="添加秒杀商品" v-if="$route.query.id ? false : true">
        <el-button type="primary" @click="centerDialogVisible = true"
          >+添加商品</el-button
        >
      </el-form-item>
    </el-form>
    <commonTable
      :tableData="tableData"
      :total="tableData.length"
      :paginationVisible="false"
    >
      <template v-slot:table>
        <el-table-column
          prop="goodsId"
          align="center"
          label="产品ID"
          show-overflow-tooltip
        />
        <el-table-column prop="goodsName" align="center" label="产品" />
        <el-table-column align="center" prop="typeName" label="分类" />
        <el-table-column prop="stockNum" align="center" label="库存" />
        <el-table-column
          prop="dealNum"
          align="center"
          label="总销量"
        />
        <!-- <el-table-column
          :prop="$route.query.id ? 'totalBuyNum' : 'dealNum'"
          align="center"
          label="总销量"
        /> -->
        <el-table-column align="center" label="发布时间">
          <template slot-scope="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <p class="">选择商品规格</p>
    <commonTable :tableData="specList" :total="specList.length">
      <template v-slot:table>
        <el-table-column prop="title" align="center" label="商品规格" />
        <el-table-column prop="stock" align="center" label="商品库存" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
                {{scope.row.price | formatAmount}}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="500">
          <template slot-scope="scope">
            秒杀金额
            <el-input
              @change="
                () => {
                  scope.row.killPrice =
                    scope.row.killPrice < 0.01 ? 0.01 : scope.row.killPrice;
                }
              "
              class="d-inline-block w100"
              v-model="scope.row.killPrice"
            />
            元 活动库存
            <el-input
              class="d-inline-block w100"
              @change="
                () => {
                  scope.row.killNum =
                    scope.row.killNum < 1
                      ? 1
                      : scope.row.killNum.replace(/\D/g, '');
                }
              "
              v-model="scope.row.killNum"
            />
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="选择商品"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      width="60%"
      center
    >
      <product v-if="centerDialogVisible" ref="product" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <div
      class="mt20 d-flex justify-content-center"
      v-if="$route.query.id ? false : true"
    >
      <el-button @click="save(0)" type="primary">保存到草稿箱</el-button>
      <el-button @click="save(1)">发布</el-button>
    </div>
    <div class="mt20 d-flex justify-content-center" v-else>
      <el-button type="primary" @click="save(draftType)">保存编辑</el-button>
    </div>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import product from "./product";
import { getDataTimeSec } from "@/utils";
import {
  addKillGoodsInfo,
  updateKillGoodsInfo,
} from "@/api/marketingActivities";
import { get, post } from "../../../../utils/request";
import {showErrorToast,formatAmount} from '@/utils/utils'
export default {
  data() {
    return {
      ruleForm: {
        killType: "", // 活动类型
        pickerValue: "",
        maxPurchase: "",
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      rules: {
        killType: [{ required: true, message: "请选择活动类型" }],
        pickerValue: [{ required: true, message: "请选择活动时长" }],
      },
      types: [
        { label: "限时秒杀", id: 1 },
        { label: "促销秒杀", id: 2 },
      ],
      tableData: [],
      specList: [],
      setSpec: [],
      centerDialogVisible: false,
      draftType: "",
      killNo: "",
    };
  },
  filters:{
    formatAmount(amount){
      return formatAmount(amount)
    }
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  components: {
    commonTable,
    product,
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      this.ruleForm.killId=id;
      this.initDetail()
    }
  },
  methods: {
     initDetail() {
      get("/secKill/" + this.ruleForm.killId).then((res) => {
        res.pickerValue = [res.startTime, res.endTime];
        delete res.startTime;
        delete res.endTime;
        this.ruleForm = res;
        get("/goods/" + res.goodsId).then((res) => {
          this.tableData = [
            {
              goodsId:res.goodsId,
              goodsName:res.goodsName,
              createTime:res.createTime,
              typeName:res.typeName,
              stockNum: res.stockNum,
              createTime: res.createTime,
              dealNum:  res.dealNum
            }
          ];
          res.skuList.forEach(item=>{
            item.killPrice=item.killPrice/100
          })
          this.specList = res.skuList;
        });
      });
    },
    determine() {
      const { multipleSelection } = this.$refs.product;
      this.tableData = [multipleSelection];
      this.centerDialogVisible = false;
      get("/goods/" + multipleSelection.goodsId).then((res) => {
        this.specList = res.skuList;
      });
    },
    /**@method  发布*/
    save(type) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (this.tableData.length == 0) {
          this.$message({
            message: "请先选择商品",
            type: "warning",
          });
          return;
        }
        var hasError=false;
        this.specList.forEach((item) => {
          if(!item.killPrice){
            hasError=true;
            showErrorToast(this,"秒杀金额不能为空");
            return;
          }
          if(!item.killNum){
            hasError=true;
            showErrorToast(this,"秒杀数量不能为空");
            return;
          }
          if (item.killPrice > item.price) {
            hasError=true;
            showErrorToast(this,"秒杀金额不能大于商品金额");
            return;
          }
          if (item.killNum > item.stock) {
            hasError=true;
            showErrorToast(this,"秒杀库存不能大于商品库存");
            return;
          }
        });
        if(hasError){
          return;
        }
        var params = {};
        Object.assign(params,this.ruleForm)
        params.goodsId=this.tableData[0].goodsId
        delete params.pickerValue
        params.startTime=this.ruleForm.pickerValue[0]+' 00:00:00'
        params.endTime=this.ruleForm.pickerValue[1]+' 23:59:59'
        params.draftType=type;
        var skuList=[]
        this.specList.forEach(item=>{
            skuList.push({id:item.id,killPrice: item.killPrice*100,killNum:item.killNum})
        })
        params.skuList=skuList;
        post("/secKill", params).then((res) => {
           this.$router.go(-1);
        });
      });
    },
    /**@method 获取编辑内容 */
    async updateKillGoodsInfo(id) {
      const result = await updateKillGoodsInfo(
        { killId: id },
        { showLoading: true }
      );
      const {
        killType,
        startTime,
        endTime,
        maxPurchase,
        goodSpecModelList,
        goodsName,
        stockNum,
        killNo,
        draftType,
        goodSpecItems,
      } = result.data;
      this.ruleForm.killType = killType;
      this.ruleForm.pickerValue = [
        this.setGetDataTimeSec(startTime),
        this.setGetDataTimeSec(endTime),
      ];
      this.ruleForm.maxPurchase = maxPurchase;
      this.tableData = [result.data];
      this.killNo = killNo;
      this.draftType = draftType;
      const arr = [];
      this.setSpec = [];
      //  for (let i = 0; i < goodSpecModelList.length; i++) {
      //     let type = goodSpecModelList[i];
      //     for (let j = 0; j < type.goodSpecModelList.length; j++) {
      //       let spec = type.goodSpecModelList[j];
      //       spec.goodsName = goodsName;
      //       spec.typeName = type.specName;
      //       spec.stockNum = stockNum;
      //       this.setSpec.push({
      //         killPrice: spec.killPrice / 100,
      //         killNum: spec.killNum,
      //         stockNum: spec.stockNum,
      //         price: spec.price,
      //         specId: spec.specId,
      //       });
      //       arr.push(spec);
      //     }
      //   }
      //  this.specList = arr;
      for (let i = 0; i < goodSpecItems.length; i++) {
        let spec = goodSpecItems[i];
        spec.goodsName = goodsName;
        this.setSpec.push({
          killPrice: spec.killPrice / 100,
          killNum: spec.killNum,
          stockNum: spec.stock,
          price: spec.sellPrice,
          specId: spec.specsId.split("_")[1],
          specsId: spec.specsId,
        });
      }
      this.specList = goodSpecItems;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
